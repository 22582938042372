import React, { useContext, useEffect } from 'react';
import './footer.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ApiContext from '../../context/ApiContext';

const Footer = () => {

  const { retrieveFooterData, retrieveHeaderData, headerData, footerData } = useContext(ApiContext);

	useEffect(() => {
		retrieveHeaderData();
    retrieveFooterData();
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


  return (
    <footer>
      <a href='#' className='footer__logo'>{undefined === footerData ? "" : footerData.organization}</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className='footer__socials'>
        <a href={undefined === footerData ? "" : footerData.facebook} target='_blank' rel='noopener noreferrer'><FacebookIcon/></a>
        <a href={undefined === footerData ? "" : footerData.instagram} target='_blank' rel='noopener noreferrer'><InstagramIcon/></a>
        <a href={undefined === headerData ? "" : headerData.linkedIn} target='_blank' rel='noopener noreferrer'><LinkedInIcon/></a>
        <a href={undefined === headerData ? "" : headerData.github} target='_blank' rel='noopener noreferrer'><GitHubIcon/></a>
      </div>

      <div className='footer__copyright'>
        <small>&copy; {undefined === headerData ? "" : headerData.name}. All rights reserved</small>
      </div>
      
    </footer>
  )
}

export default Footer