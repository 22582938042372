import React, {useState} from 'react'
import './navbar.css'
import HomeIcon from '@mui/icons-material/Home';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import ConnectWithoutContactRoundedIcon from '@mui/icons-material/ConnectWithoutContactRounded';
// import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';

const Navbar = () => {
  const [active, setActive] = useState("#")
  return (
    <nav>
      <a href="#" onClick={() => setActive("#")} className={active === "#" ? "active": ""}><HomeIcon/></a>
      <a href="#about" onClick={() => setActive("#about")} className={active === "#about" ? "active": ""}><PersonRoundedIcon/></a>
      <a href="#experience" onClick={() => setActive("#experience")} className={active === "#experience" ? "active": ""}><ClassRoundedIcon/></a>
      {/* <a href="#portfolio" onClick={() => setActive("#portfolio")} className={active === "#portfolio" ? "active": ""}><AccountTreeRoundedIcon/></a> */}
      <a href="#contact" onClick={() => setActive("#contact")} className={active === "#contact" ? "active": ""}><ConnectWithoutContactRoundedIcon/></a>
    </nav>
  )
}

export default Navbar;