import React, { useContext, useEffect, useRef } from "react";
import "./admin.css";
import { Grid, Snackbar, LinearProgress } from "@mui/material";
import ApiContext from "../../context/ApiContext";

const AdminFooter = () => {
	const {
		onFooterChange,
		retrieveFooterData,
		submitFooterData,
		updateFooterData,
		footerData,
		OK,
		loading,
		open,
		vertical,
		horizontal,
		handleClose,
		handleClick,
		Alert,
	} = useContext(ApiContext);

	const form = useRef();

	useEffect(() => {
		retrieveFooterData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let submitOrUpdateFooterData = async (e) => {
		e.preventDefault();
		if (footerData.hasBeenFilled) {
			updateFooterData();
		} else {
			submitFooterData();
		}
	};

	return (
		<Grid item xs={6}>
			<h3 center>Footer</h3>
			<form ref={form} onSubmit={submitOrUpdateFooterData}>
				<input
					type="text"
					name="organization"
					placeholder="Organization"
					value={undefined === footerData ? "" : footerData.organization}
					onChange={(e) => onFooterChange(e)}
				/>
				<input
					type="text"
					name="facebook"
					placeholder="Facebook"
					value={undefined === footerData ? "" : footerData.facebook}
					onChange={(e) => onFooterChange(e)}
				/>
				<input
					type="text"
					name="instagram"
					placeholder="Instagram"
					value={undefined === footerData ? "" : footerData.instagram}
					onChange={(e) => onFooterChange(e)}
				/>
				<button
					className="btn btn-primary"
					type="submit"
					disabled={loading}
					onClick={handleClick({
						vertical: "top",
						horizontal: "center",
					})}
				>
					Save details
				</button>
				{loading ? (
					<Snackbar
						open={open}
						autoHideDuration={6000}
						onClose={handleClose}
						anchorOrigin={{ vertical, horizontal }}
						key={`${vertical},${horizontal}`}
					>
						<Alert
							onClose={handleClose}
							severity={"info"}
							sx={{ width: "100%" }}
						>
							<LinearProgress color="inherit" />
							Updating footer data...
						</Alert>
					</Snackbar>
				) : (
					<Snackbar
						open={open}
						autoHideDuration={4500}
						onClose={handleClose}
						anchorOrigin={{ vertical, horizontal }}
						key={vertical + horizontal}
					>
						<Alert
							onClose={handleClose}
							severity={OK ? "success" : "error"}
							sx={{ width: "100%" }}
						>
							{OK
								? "Footer data updated successfully"
								: "Failed to update footer data"}
						</Alert>
					</Snackbar>
				)}
			</form>
		</Grid>
	);
};

export default AdminFooter;
