import React, { useEffect, useContext } from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/me.png";
import HeaderSocials from "./HeaderSocials";
import ApiContext from "../../context/ApiContext";

const Header = () => {

	const { retrieveHeaderData, headerData } = useContext(ApiContext);

	useEffect(() => {
		retrieveHeaderData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<header>
			<div className="container header__container">
				<h5>Hello I'm</h5>
				<h1>{undefined === headerData ? "" : headerData.name}</h1>
				<h5 className="text-light">{undefined === headerData ? "" : headerData.title}</h5>
				<CTA />
				<HeaderSocials />

				<div className="me">
					<img src={ME} alt="me" />
				</div>

				<a href="#contact" className="scroll__down">
					Scroll Down
				</a>
			</div>
		</header>
	);
};

export default Header;
