import React, { useContext, useEffect } from "react";
import "./admin.css";
import { Grid, Snackbar, LinearProgress } from "@mui/material";
import ApiContext from "../../context/ApiContext";

const AdminHeader = () => {
	const {
		onHeaderChange,
		retrieveHeaderData,
		submitHeaderData,
		updateHeaderData,
		headerData,
		OK,
		loading,
		open,
		vertical,
		horizontal,
		handleClose,
		handleClick,
		Alert,
	} = useContext(ApiContext);

	useEffect(() => {
		retrieveHeaderData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let submitOrUpdateHeaderData = async (e) => {
		e.preventDefault();
		if (headerData.hasBeenFilled) {
			updateHeaderData();
		} else {
			submitHeaderData();
		}
	};

	return (
		<Grid item xs={6}>
			<h3 center>Header</h3>
			<form onSubmit={submitOrUpdateHeaderData}>
				<input
					type="text"
					name="title"
					placeholder="Title"
					value={undefined === headerData ? "" : headerData.title}
					onChange={(e) => onHeaderChange(e)}
				/>
				<input
					type="text"
					name="name"
					placeholder="Name"
					value={undefined === headerData ? "" : headerData.name}
					onChange={(e) => onHeaderChange(e)}
				/>
				<input
					type="text"
					name="linkedIn"
					placeholder="LinkedIn"
					value={undefined === headerData ? "" : headerData.linkedIn}
					onChange={(e) => onHeaderChange(e)}
				/>
				<input
					type="text"
					name="github"
					placeholder="Github"
					value={undefined === headerData ? "" : headerData.github}
					onChange={(e) => onHeaderChange(e)}
				/>
				<button
					className="btn btn-primary"
					type="submit"
					disabled={loading}
					onClick={handleClick({
						vertical: "top",
						horizontal: "center",
					})}
				>
					Save details
				</button>
				{loading ? (
					<Snackbar
						open={open}
						autoHideDuration={6000}
						onClose={handleClose}
						anchorOrigin={{ vertical, horizontal }}
						key={`${vertical},${horizontal}`}
					>
						<Alert
							onClose={handleClose}
							severity={"info"}
							sx={{ width: "100%" }}
						>
							<LinearProgress color="inherit" />
							Updating header data...
						</Alert>
					</Snackbar>
				) : (
					<Snackbar
						open={open}
						autoHideDuration={4500}
						onClose={handleClose}
						anchorOrigin={{ vertical, horizontal }}
						key={vertical + horizontal}
					>
						<Alert
							onClose={handleClose}
							severity={OK ? "success" : "error"}
							sx={{ width: "100%" }}
						>
							{OK
								? "Header data updated successfully"
								: "Failed to update header data"}
						</Alert>
					</Snackbar>
				)}
			</form>
		</Grid>
	);
};

export default AdminHeader;
