import React, { useContext } from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ApiContext from "../../context/ApiContext";

const HeaderSocials = () => {
	const { headerData } = useContext(ApiContext);

	return (
		<div className="header__socials">
			<a
				href={
					undefined === headerData 
						? "https://www.linkedin.com/" 
						: headerData.linkedIn
				}
				target="_blank"
				rel="noopener noreferrer"
			>
				{<LinkedInIcon />}
			</a>
			<a
				href={
					undefined === headerData
						? "https://github.com/"
						: headerData.github
				}
				target="_blank"
				rel="noopener noreferrer"
			>
				{<GitHubIcon />}
			</a>
		</div>
	);
};

export default HeaderSocials;
