import React from "react";
import "./admin.css";
import { Grid } from "@mui/material";

const AdminPortfolio = () => {
	return (
		<Grid item xs={6}>
			<h3 center>Portfolio</h3>
			<form >
				<input
					type="text"
					name="description"
					placeholder="Description"
					// value={"Description about me"}
					// onChange={(e) => onChange(e)}
				/>
				<input
					type="text"
					name="github"
					placeholder="Github"
					// value={"Description about me"}
					// onChange={(e) => onChange(e)}
				/>
				<button className="btn btn-primary" type="submit">
					Save details
				</button>
			</form>
		</Grid>
	);
};

export default AdminPortfolio;
