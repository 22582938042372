import React, { useContext, useEffect } from "react";
import "./about.css";
import ME from "../../assets/me-about.JPG";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ApiContext from "../../context/ApiContext";

const About = () => {

	const { retrieveAboutData, aboutData } = useContext(ApiContext);

	useEffect(() => {
		retrieveAboutData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section id="about">
			<h5>Get to know</h5>
			<h2>About Me</h2>

			<div className="container about__container">
				<div className="about__me">
					<div className="about__me-image">
						<img src={ME} alt="About Me" />
					</div>
				</div>

				<div className="about__content">
					<div className="about__cards">
						<article className="about__card">
							<EmojiEventsRoundedIcon className="about__icon" />
							<h5>Experience</h5>
							<small>3+ years experience</small>
						</article>
						{/* <article className="about__card">
							<PeopleAltIcon className="about__icon" />
							<h5>Clients</h5>
							<small>I have some clients</small>
						</article> */}
						<article className="about__card">
							<FolderCopyIcon className="about__icon" />
							<h5>Projects</h5>
							<small>10+</small>
						</article>
					</div>

					<p>
						{undefined === aboutData ? "" : aboutData.description}
					</p>

					<a href="#contact" className="btn btn-primary">
						Contact me
					</a>
				</div>
			</div>
		</section>
	);
};

export default About;
