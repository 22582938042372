import { createContext, useState, forwardRef } from "react";
import MuiAlert from "@mui/material/Alert";

const ApiContext = createContext();

export default ApiContext;

export const ApiProvider = ({ children }) => {

	const [OK, setOK] = useState(false);
	const [loading, setLoading] = useState(false);
    const [headerData, setHeaderData] = useState({});
    const [aboutData, setAboutData] = useState({});
    const [contactData, setContactData] = useState({});
    const [footerData, setFooterData] = useState({});

    const [state, setState] = useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});

	const { vertical, horizontal, open } = state;

    const Alert = forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const handleClick = (newState) => () => {
		setState({ open: true, ...newState });
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setState({ ...state, open: false });
	};

    const onHeaderChange = (e) => {
		setHeaderData((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

    const onAboutChange = (e) => {
        setAboutData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const onContactChange = (e) => {
        setContactData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    const onFooterChange = (e) => {
        setFooterData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    let retrieveHeaderData = async () => {
        let response = await fetch("/api/retrieve/header-data", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let data = await response.json();
        if (response.status === 200) {
            setHeaderData(data[0]);
        }
    }

    let submitHeaderData = async (e) => {
        setOK(false);
        setLoading(true);
		let response = await fetch("/api/submit/header-data", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
                title: headerData.title,
				name: headerData.name,
				linkedIn: headerData.linkedIn,
				github: headerData.github,
			}),
		});
		let data = await response.json();
		if (response.status === 200) {
			setHeaderData(data);
			console.log(data);
            setLoading(false);
            setOK(true);
		}
        else {
            setLoading(false);
            setOK(false);
            console.error(data);
        }
	};

    let updateHeaderData = async () => {
        setOK(false);
        setLoading(true);
        let response = await fetch("/api/update/header-data", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: headerData.title,
                name: headerData.name,
                linkedIn: headerData.linkedIn,
                github: headerData.github,
            }),
        });
        let data = await response.json();
        if (response.status === 200) {
            setHeaderData(data);
            console.log(data);
            setLoading(false);
            setOK(true);
		}
        else {
            setLoading(false);
            setOK(false);
            console.error(data);
        }
    }

    let retrieveAboutData = async () => {
        let response = await fetch("/api/retrieve/about-me-data", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let data = await response.json();
        if (response.status === 200) {
            setAboutData(data[0]);
        }
    }

    let submitAboutData = async (e) => {
        setOK(false);
        setLoading(true);
        let response = await fetch("/api/submit/about-me-data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                description: aboutData.description,
            }),
        });
        let data = await response.json();
        if (response.status === 200) {
            setAboutData(data);
            console.log(data);
            setLoading(false);
            setOK(true);
		}
        else {
            setLoading(false);
            setOK(false);
            console.error(data);
        }
    }

    let updateAboutData = async () => {
        setOK(false);
        setLoading(true);
        let response = await fetch("/api/update/about-me-data", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                description: aboutData.description,
            }),
        });
        let data = await response.json();
        if (response.status === 200) {
            setAboutData(data);
            console.log(data);
            setLoading(false);
            setOK(true);
		}
        else {
            setLoading(false);
            setOK(false);
            console.error(data);
        }
    }

    let retrieveContactData = async () => {
        let response = await fetch("/api/retrieve/contact-data", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let data = await response.json();
        if (response.status === 200) {
            setContactData(data[0]);
        }
    }

    let submitContactData = async (e) => {
        setOK(false);
        setLoading(true);
        let response = await fetch("/api/submit/contact-data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: contactData.email,
                phone: contactData.phone,
            }),
        });
        let data = await response.json();
        if (response.status === 200) {
            setContactData(data);
            console.log(data);
            setLoading(false);
            setOK(true);
		}
        else {
            setLoading(false);
            setOK(false);
            console.error(data);
        }
    }

    let updateContactData = async () => {
        setOK(false);
        setLoading(true);
        let response = await fetch("/api/update/contact-data", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: contactData.email,
                phone: contactData.phone,
            }),
        });
        let data = await response.json();
        if (response.status === 200) {
            setContactData(data);
            console.log(data);
            setLoading(false);
            setOK(true);
		}
        else {
            setLoading(false);
            setOK(false);
            console.error(data);
        }
    }

    let retrieveFooterData = async () => {
        let response = await fetch("/api/retrieve/footer-data", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let data = await response.json();
        if (response.status === 200) {
            setFooterData(data[0]);
        }
    }

    let submitFooterData = async () => {
        setOK(false);
        setLoading(true);
        let response = await fetch("/api/submit/footer-data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                organization: footerData.organization,
                facebook: footerData.facebook,
                instagram: footerData.instagram
            }),
        });
        let data = await response.json();
        if (response.status === 200) {
            setFooterData(data);
            console.log(data);
            setLoading(false);
            setOK(true);
		}
        else {
            setLoading(false);
            setOK(false);
            console.error(data);
        }
    }

    let updateFooterData = async () => {
        setOK(false);
        setLoading(true);
        let response = await fetch("/api/update/footer-data", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                organization: footerData.organization,
                facebook: footerData.facebook,
                instagram: footerData.instagram
            }),
        });
        let data = await response.json();
        if (response.status === 200) {
            setFooterData(data);
            console.log(data);
            setLoading(false);
            setOK(true);
		}
        else {
            setLoading(false);
            setOK(false);
            console.error(data);
        }
    }

    let contextData = {
        headerData: headerData,
        aboutData: aboutData,
        contactData: contactData,
        footerData: footerData,
        OK: OK,
        loading: loading,
        state: state,
        open: open,
        vertical: vertical,
        horizontal: horizontal,
        Alert: Alert,

        onHeaderChange: onHeaderChange,
        onAboutChange: onAboutChange,
        onContactChange: onContactChange,
        onFooterChange: onFooterChange,
        submitHeaderData: submitHeaderData,
        updateHeaderData: updateHeaderData,
        retrieveHeaderData: retrieveHeaderData,
        submitAboutData: submitAboutData,
        updateAboutData: updateAboutData,
        retrieveAboutData: retrieveAboutData,
        submitContactData: submitContactData,
        updateContactData: updateContactData,
        retrieveContactData: retrieveContactData,
        submitFooterData: submitFooterData,
        retrieveFooterData: retrieveFooterData,
        updateFooterData: updateFooterData,
        setOK: setOK,
        setLoading: setLoading,
        setState: setState,
        handleClick: handleClick,
        handleClose: handleClose,
    };

    return (
        <ApiContext.Provider value={contextData}>
            {children}
        </ApiContext.Provider>
    );
}