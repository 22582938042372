import React from "react";
import "./admin.css";
import { Box, Grid } from "@mui/material";
import AdminHeader from "./AdminHeader";
import AdminAbout from "./AdminAbout";
import AdminExperience from "./AdminExperience";
import AdminPortfolio from "./AdminPortfolio";
import AdminContact from "./AdminContact";
import AdminFooter from "./AdminFooter";

const Admin = () => {
	return (
		<section id="admin">
			<h2>Change user details</h2>
			<div className="container admin__container">
				<div className="admin__forms">
					<Box sx={{ width: "100%" }}>
						<Grid
							container
							rowSpacing={2}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<AdminHeader />
							<AdminAbout />
							<AdminExperience />
							<AdminPortfolio />
							<AdminContact />
							<AdminFooter />
						</Grid>
					</Box>
				</div>
			</div>
		</section>
	);
};

export default Admin;
