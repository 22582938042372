import React from "react";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Experience from "./components/experience/Experience";
import Header from "./components/header/Header";
import Navbar from "./components/navbar/Navbar";
// import Portfolio from "./components/portfolio/Portfolio";
import Footer from "./components/footer/Footer";


const MainRouter = () => {
	return (
		<>
			<Header />
			<Navbar />
			<About />
			<Experience />
			{/* <Portfolio /> */}
			<Contact />
			<Footer />
		</>
	);
};

export default MainRouter;
