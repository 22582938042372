import React from "react";
import MainRouter from "./MainRouter";
import Admin from "./components/admin/Admin";
import { ApiProvider } from "./context/ApiContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
	return (
		<Router>
			<ApiProvider>
				<Routes>
					<Route path="/" element={<MainRouter />} />
					<Route path="/admin" element={<Admin />} />
				</Routes>
			</ApiProvider>
		</Router>
	);
};

export default App;
