import React, { useRef, useContext, useEffect } from "react";
import "./contact.css";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Snackbar from "@mui/material/Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import emailjs from "emailjs-com";
import ApiContext from "../../context/ApiContext";

const Contact = () => {
	const {
		retrieveContactData,
		contactData,
		setOK,
		OK,
		setLoading,
		loading,
		open,
		vertical,
		horizontal,
		handleClose,
		handleClick,
		Alert,
	} = useContext(ApiContext);

	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
		setLoading(true);
		setOK(false);
		emailjs
			.sendForm(
				"service_ex93zma",
				"template_rasakq5",
				form.current,
				"XJHFYcbJkh5nMRFD5"
			)
			.then(
				(result) => {
					form.current.reset();
					console.log(result.text);
					setLoading(false);
					setOK(true);
				},
				(error) => {
					console.log(error.text);
					setOK(false);
					form.current.reset();
				}
			);
	};

	useEffect(() => {
		retrieveContactData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section id="contact">
			<h5>Get in touch</h5>
			<h2>Contact Me</h2>

			<div className="container contact__container">
				<div className="contact__options">
					<article className="contact__option">
						<EmailIcon className="contact__option-icon" />
						<h4>Email</h4>
						<h5>{undefined === contactData ? "" : contactData.email}</h5>
						<a
							href="mailto:loma256@gmail.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							Send a message
						</a>
					</article>
					<article className="contact__option">
						<WhatsAppIcon className="contact__option-icon" />
						<h4>WhatsApp</h4>
						<h5>{undefined === contactData ? "" : contactData.phone}</h5>
						<a
							href="https://api.whatsapp.com/send?phone+00447932522801"
							target="_blank"
							rel="noreferrer noreopener"
						>
							Send a message
						</a>
					</article>
				</div>
				<form ref={form} onSubmit={sendEmail}>
					<input
						type="text"
						name="name"
						placeholder="Your Full Name"
						required
					/>
					<input type="email" name="email" placeholder="Your Email" required />
					<textarea
						name="message"
						rows="7"
						placeholder="Your Message"
						required
					/>
					<button
						type="submit"
						className="btn btn-primary"
						disabled={loading}
						onClick={handleClick({
							vertical: "top",
							horizontal: "center",
						})}
					>
						Send
					</button>
					{loading ? (
						<Snackbar
							open={open}
							autoHideDuration={6000}
							onClose={handleClose}
							anchorOrigin={{ vertical, horizontal }}
							key={`${vertical},${horizontal}`}
						>
							<Alert
								onClose={handleClose}
								severity={"info"}
								sx={{ width: "100%" }}
							>
								<LinearProgress color="inherit" />
								Sending Message to Leonard
							</Alert>
						</Snackbar>
					) : (
						<Snackbar
							open={open}
							autoHideDuration={4500}
							onClose={handleClose}
							anchorOrigin={{ vertical, horizontal }}
							key={vertical + horizontal}
						>
							<Alert
								onClose={handleClose}
								severity={OK ? "success" : "error"}
								sx={{ width: "100%" }}
							>
								{OK ? "Message sent successfully" : "Message failed to send"}
							</Alert>
						</Snackbar>
					)}
				</form>
			</div>
		</section>
	);
};

export default Contact;
