import React from "react";
import "./admin.css";
import { Grid } from "@mui/material";

const AdminExperience = () => {
	return (
		<Grid item xs={6}>
			<h3 center>Experience</h3>
			<form >
				<input
					type="text"
					name="skill"
					placeholder="Skill"
					// value={"Description about me"}
					// onChange={(e) => onChange(e)}
				/>
				<select
					type="text"
					name="experienceLevel"
					placeholder="experience Level"
					className="admin__select"
					// value={"Description about me"}
					// onChange={(e) => onChange(e)}
				>
					<option value="Basic">Basic</option>
					<option value="Intermediate">Intermediate</option>
					<option value="Advanced">Advanced</option>
				</select>
				<button className="btn btn-primary" type="submit">
					Save details
				</button>
			</form>
		</Grid>
	);
};

export default AdminExperience;
