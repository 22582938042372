import React, { useContext, useEffect, useRef } from "react";
import "./admin.css";
import { Grid, Snackbar, LinearProgress } from "@mui/material";
import ApiContext from "../../context/ApiContext";

const AdminContact = () => {
	const {
		onContactChange,
		retrieveContactData,
		submitContactData,
		updateContactData,
		contactData,
		OK,
		loading,
		open,
		vertical,
		horizontal,
		handleClose,
		handleClick,
		Alert,
	} = useContext(ApiContext);

	const form = useRef();

	useEffect(() => {
		retrieveContactData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let submitOrUpdateContactData = async (e) => {
		e.preventDefault();
		if (contactData.hasBeenFilled) {
			updateContactData();
		} else {
			submitContactData();
		}
	};

	return (
		<Grid item xs={6}>
			<h3 center>Contact</h3>
			<form ref={form} onSubmit={submitOrUpdateContactData}>
				<input
					type="text"
					name="email"
					placeholder="Email"
					value={undefined === contactData ? "" : contactData.email}
					onChange={(e) => onContactChange(e)}
				/>
				<input
					type="text"
					name="phone"
					placeholder="Phone"
					value={undefined === contactData ? "" : contactData.phone}
					onChange={(e) => onContactChange(e)}
				/>
				<button 
					className="btn btn-primary" 
					type="submit"
					disabled={loading}
					onClick={handleClick({
						vertical: "top",
						horizontal: "center",
					})}
				>
					Save details
				</button>
				{loading ? (
					<Snackbar
						open={open}
						autoHideDuration={6000}
						onClose={handleClose}
						anchorOrigin={{ vertical, horizontal }}
						key={`${vertical},${horizontal}`}
					>
						<Alert
							onClose={handleClose}
							severity={"info"}
							sx={{ width: "100%" }}
						>
							<LinearProgress color="inherit" />
							Updating contact data...
						</Alert>
					</Snackbar>
				) : (
					<Snackbar
						open={open}
						autoHideDuration={4500}
						onClose={handleClose}
						anchorOrigin={{ vertical, horizontal }}
						key={vertical + horizontal}
					>
						<Alert
							onClose={handleClose}
							severity={OK ? "success" : "error"}
							sx={{ width: "100%" }}
						>
							{OK
								? "Contact data updated successfully"
								: "Failed to update contact data"}
						</Alert>
					</Snackbar>
				)}
			</form>
		</Grid>
	);
};

export default AdminContact;
